<template>
  <div>
    <!-- 引入的返回头组件 -->
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div class="desk-right">
      <div class="record">
        <div class="records">
          <div class="right-top flex flex-ac flex-jsb">
            <div class="flex" style="width: 50%">
              <div
                class="top-item flex flex-jc"
                v-for="(item, index) in tabList"
                :key="index"
                @click="selectTab(index)"
              >
                <div
                  class="color-666 ft-28 flex flex-column flex-ac tab-item"
                  :class="chooseIndex == index ? 'active-tab' : ''"
                >
                  <div class="activetab-text">
                    {{ (item.tab_name || "") }}<template v-if="index != 3">({{ item.num || 0 }})</template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- <div style="height: calc(65vh - 7rem)">
          
        </div> -->
        <div class="index_list">
          <div v-for="(item, i) in coupon_list" :key="i" class="index_box"
            :class="{
              'used-box': chooseIndex == 1,
              'expired-box': chooseIndex == 2,
            }"
          >
            <img v-if="chooseIndex == 1" class="coupon-tag-img" src="@/static/images/coupon-used.png" />
            <img v-if="chooseIndex == 2" class="coupon-tag-img" src="@/static/images/coupon-expired.png" />
            <div class="coupon-left">
              <div class="color-1588F5 mg-bt15">
                <span class="mg-rt5 ft-40 ft-bold">{{ item.face }}</span>
                <span class="ft-22" v-if="item.type == 1" style="vertical-align: super;">元</span>
                <span class="ft-22" v-if="item.type == 2" style="vertical-align: super;">折</span>
              </div>
              <div class="ft-20 color-333">满{{ item.cill }}可用</div>
            </div>
            <div style="flex: 1;" class="pd-lt20 pd-rt5">
              <div class="ft-22 color-333 mg-bt25">{{ item.name }}</div>
              <!-- <div v-if="item.period == 1" class="ft-20 color-666">领券后{{ item.day }}天内有效</div> -->
              <div v-if="item.period == 1 || item.period == 2" class="ft-20 color-666">有效期：{{ item.start_time }} 至 {{ item.end_time }}</div>
              <div v-if="item.period == 3" class="ft-20 color-666">有效期：长期有效</div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <van-pagination
            v-model="page"
            :total-items="count"
            :items-per-page="limit"
            mode="simple"
            force-ellipses
            style="background-color: #f2f2f2"
            @change="getCouponList(chooseIndex, 1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatTimeWitchLine } from "@/utils/util";
import { datetimePickerFormatter, formatTime } from '../../utils/util';
import titlebar from "@/views/component/titlebar";
export default {
  components: {
    titlebar
  },
  data() {
    return {
      tabList: [
        { tab_name: "未使用", num: 0 },
        { tab_name: "已使用", num: 0 },
        { tab_name: "已过期", num: 0 },
      ],
      chooseIndex: 0,
      member_id: 0,
      page_name: 'member-coupon',
      page: 1,
      limit: 12,
      count: 0,
      coupon_list: [],
    };
  },
  created() {
    this.member_id = this.$route.query.id;
    this.getCouponList(0, 1);
    this.getCouponList(1);
    this.getCouponList(2);
  },
  methods: {
    getCouponList(type, name) {
      let params = {
        type: type,
        page: this.page,
        limit: this.limit,
        uid: this.member_id,
      }
      this.$api.Coupon_memberCoupon(params).then(res => {
        if (res.code == 1) {
          const list = res.data.data || [];
          const count = res.data.count || 0;
          if (name == 1) {
            this.coupon_list = list;
            this.count = count;
            this.coupon_list.forEach(element => {
              if (element.period == 1) {
                element.start_time = formatTime(new Date(element.create_time), 'yyyy-MM-dd');
                element.end_time = formatTime(new Date(element.expire_time), 'yyyy-MM-dd');
              } else if (element.period == 2) {
                element.start_time = formatTime(new Date(element.start_time), 'yyyy-MM-dd');
                element.end_time = formatTime(new Date(element.end_time), 'yyyy-MM-dd');
              }
            });
          }
          this.tabList[`${type}`].num = count;
        } else {
          this.$toast(res.msg);
        }
      })
    },
    goBack() {
      this.$router.replace({
        name: 'vipManagement',
        params: {
          page: this.$route.query.page,
          content: this.$route.query.content,
          active: this.$route.query.active,
        },
      })
    },
    // 点击tab切换
    selectTab(index) {
      this.chooseIndex = index;
      this.page = 1;
      this.count = 0;
      this.coupon_list = [];
      this.getCouponList(this.chooseIndex, 1);
    },
  },
};
</script>
<style lang="less" scoped>
.index_list {
  width: calc(100% - 4rem);
  // height: 50rem;
  height: calc(65vh - 7rem);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 32.8%);
  grid-template-rows: repeat(auto-fill, 15rem);
  grid-gap: 2.5rem 1%;
  overflow-x: hidden;
  overflow-y: scroll;

  .addpro-index-box {
    display: flex;
    align-items: center;
    justify-content: center !important;
    font-size: 2.8rem;
    color: #1588F5;
    font-weight: bold;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  .index_box {
    position: relative;
    // width: 32.9rem;
    // height: 16rem;
    background: #ffffff;
    // box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;
    padding: 0.5rem 1.5rem;
    height: 15rem;
    border: 0.2rem #cdcdcd solid;
    border-radius: 0.8rem;

    .coupon-tag-img {
      position: absolute;
      top: -1.2rem;
      right: 0;
      width: 11rem;
      height: 11rem;
    }

    .coupon-left {
      width: 26.5%;
      position: relative;
    }
    .coupon-left::after {
      content: '';
      width: 0.15rem;
      height: 132%;
      position: absolute;
      right: 0;
      top: -15%;
      border-right: 0.15rem dashed #E5E5E5;
    }
  }
  .used-box {
    opacity: 0.5;
  }
  .expired-box {
    div {
      color: #999;
    }
  }
}
.width-9 {
  width: 9%;
}
.width-10 {
  width: 12%;
}
.desk-right {
  height: calc(100vh - 19.7rem);
  margin-top: 1.5rem;
  .record {
    margin-top: 1.5rem;
    height: 100%;
    background: #fff;
    border-radius: 0.8rem;
    padding: 0 2rem;
    .records {
      width: 100%;
      .right-top {
        width: 100%;
        padding: 1.5rem 0 3rem;
        height: 6rem;
        background: #fff;
        .top-item {
          width: 25%;
          //   border-right: 0.07rem solid #ccc;
        }
        .tab-item {
          padding-left: 1rem;
          padding-right: 1rem;
          .activetab-text {
            z-index: 2;
          }
        }
      }
    }
  }
  .ordernum-input {
    border: 0.2rem #DEDEDE solid;
    width: 19rem;
    height: 5.5rem;
    border-radius: 0.8rem;
    font-size: 2.6rem;
    padding-left: 1.5rem;
  }
  .search-date-input {
    width: 19rem;
    font-size: 2.6rem;
    text-align: center;
  }
  .screenbtn {
    background: #1588f5;
    font-size: 2.4rem;
    color: #fff;
    height: 5.5rem;
    padding: 0 3rem;
    border-radius: 0.8rem;
    margin: 0 0.5rem 0 1rem;
  }
}
.active-tab {
  position: relative;
  color: #333;
  font-weight: bold;
  .activetab-text {
    font-weight: bold;
  }
}
.active-tab::after {
  content: "";
  position: absolute;
  bottom: -2.5rem;
  width: 100%;
  color: #1588f5;
  border-bottom: 1.7rem solid #1588f5;
  transition: 0.3s;
  animation: 0.8s widen;
}
@keyframes widen {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  background: white;
  position: relative;
  display: table;
  // overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
  height: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead td {
    border: 0.2rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    // overflow-y: scroll;
    display: block;
    height: calc(100% - 9rem);
    width: 100%;
    overflow-x: hidden;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 9rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      height: 9rem;
      border: 0.2rem rgba(0, 0, 0, 0.06) solid;
      word-break: break-all;
    }
  }
}
.pagination {
  position: absolute;
  bottom: 1.5rem;
  right: 0;
  width: 20%;
}
/deep/.van-pagination__item--active {
  background-color: #1588f5;
}
</style>